<template>
	<div class="state clearfix">
		<div class="left">
			<p class="number">订单号：{{order.order_no}}</p>
			<p class="state-txt green">{{ order.state_text }}</p>
			<div v-if="order.order_status == OrderStatusEnum.NORMAL.value">
				<p v-if="order.pay_status == PayStatusEnum.PENDING.value" 
				@click="onPay">
					<el-button size="mini" plain>去支付</el-button>
				</p>
				<p v-if="order.delivery_status == DeliveryStatusEnum.DELIVERED.value && order.receipt_status == ReceiptStatusEnum.NOT_RECEIVED.value"
				 @click="onReceipt">
					<el-button size="mini" plain>确认收货</el-button>
				</p>
			</div>
			<!-- <p v-if="order.order_status == OrderStatusEnum.COMPLETED.value && order.is_comment == 0"
			@click="handleTargetComment()">
				<el-button size="mini" plain>评价</el-button>
			</p>
			<p v-if="order.order_status == OrderStatusEnum.COMPLETED.value && order.is_invoice == 0"
			@click="handleInvoice">
				<el-button size="mini" plain>申请电子发票</el-button>
			</p>
			<p v-if="order.order_status == OrderStatusEnum.COMPLETED.value && order.is_invoice == 1"
			@click="handleInvoicePdf">
				<el-button size="mini" plain>下载发票</el-button>
			</p> -->
		</div>
		<!-- <div class="right">
			<el-steps :active="stepActive" finish-status="success" align-center>
				<el-step title="提交订单" :description="order.create_time"></el-step>
				<el-step title="付款成功" :description="stepActive >= 2 ? order.pay_time : ''"></el-step>
				<el-step title="商品发货" :description="stepActive >= 3 ? order.delivery_time : ''"></el-step>
				<el-step title="确认收货" :description="stepActive >= 4 ? order.receipt_time : ''"></el-step>
				<el-step title="完成" :description="stepActive >= 5 ? order.receipt_time : ''"></el-step>
			</el-steps>
			<p class="tips">本订单可获得<span>8</span>积分</p>
		</div> -->
		
		<el-dialog
		  title="选择支付方式"
		  :visible.sync="dialogVisible"
		  width="30%"
		  >
		  <div style="text-align: center;">
			  <el-button type="primary" plain icon="el-icon-coin" @click="onSelectPayType(PayTypeEnum.BALANCE.value)">{{ PayTypeEnum.BALANCE.name }}</el-button>
			  <el-button type="primary" plain icon="el-icon-money" @click="onSelectPayType(PayTypeEnum.WECHAT.value)">{{ PayTypeEnum.WECHAT.name }}</el-button>
		  </div>
		</el-dialog>
		
		<el-dialog
		  title="申请电子发票"
		  :visible.sync="dialogInvoice"
		  width="50%"
		  >
		  <el-form ref="invoiceform" :model="invoice" :rules="rules" label-width="150px">
			  <el-form-item label="发票类型" prop="invoiceLine">
			  	<el-select v-model="invoice.invoiceLine" placeholder="请选择发票类型">
			  	  <el-option label="普通电子发票" value="p"></el-option>
			  	  <el-option label="增值税电子专用发票" value="b"></el-option>
			  	</el-select>
			  </el-form-item>
		    <el-form-item label="企业名称/个人" prop="buyerName">
		      <el-input v-model="invoice.buyerName"></el-input>
		    </el-form-item>
		    <el-form-item label="企业税号" prop="buyerTaxNum">
				<el-input v-model="invoice.buyerTaxNum"></el-input>
		    </el-form-item>
		    <el-form-item label="电话" prop="buyerTel">
				<el-input v-model="invoice.buyerTel"></el-input>
		    </el-form-item>
		    <el-form-item label="地址" prop="buyerAddress">
				<el-input v-model="invoice.buyerAddress"></el-input>
		    </el-form-item>
		    <el-form-item label="银行账号及开户地址" prop="buyerAccount">
				<el-input v-model="invoice.buyerAccount"></el-input>
		    </el-form-item>
		    <el-form-item label="发票接收方式" prop="pushMode">
				<el-select v-model="invoice.pushMode" placeholder="请选择发票接收方式">
				  <el-option label="邮箱" value="0"></el-option>
				  <el-option label="手机" value="1"></el-option>
				  <el-option label="邮箱+手机" value="2"></el-option>
				</el-select>
		    </el-form-item>
		    <el-form-item label="接收发票手机号" prop="buyerPhone">
				<el-input v-model="invoice.buyerPhone"></el-input>
		    </el-form-item>
		    <el-form-item label="接收发票邮箱" prop="email">
				<el-input v-model="invoice.email"></el-input>
		    </el-form-item>
		    <el-form-item>
		      <el-button type="primary" @click="onApplyInvoice">申请</el-button>
		      <el-button @click="dialogInvoice = false">取消</el-button>
		    </el-form-item>
		  </el-form>
		</el-dialog>
	</div>
</template>

<script>
	import {
	  DeliveryStatusEnum,
	  DeliveryTypeEnum,
	  OrderStatusEnum,
	  PayStatusEnum,
	  PayTypeEnum,
	  ReceiptStatusEnum
	} from '@/common/enum/order'
	import * as OrderApi from '@/api/order'
	export default {
		props:{
			order: Object
		},
		data() {
			return {
				// 枚举类
				DeliveryStatusEnum,
				DeliveryTypeEnum,
				OrderStatusEnum,
				PayStatusEnum,
				PayTypeEnum,
				ReceiptStatusEnum,
				// 当前订单ID
				orderId: null,
				// 当前设置
				setting: {},
				dialogVisible: false,
				stepActive: 0,
				dialogInvoice: false,
				invoice: {
					invoiceLine: '',
					buyerName: '',
					buyerTaxNum: '',
					buyerTel: '',
					buyerAddress: '',
					buyerAccount: '',
					pushMode: '',
					buyerPhone: '',
					email: ''
				},
				rules: {
					invoiceLine: [
					  { required: true, message: '请选择发票类型', trigger: 'change' }
					],
					buyerName: [
					  { required: true, message: '请输入企业名称或个人姓名', trigger: 'blur' }
					],
					pushMode: [
					  { required: true, message: '请选择发票接收方式', trigger: 'change' }
					],
				}
			}
		},
		watch:{
			order(){
				this.orderId = this.order.order_id
				if(this.order.pay_status == PayStatusEnum.SUCCESS.value){
					this.stepActive = 2
				}
				if(this.orderdelivery_status == DeliveryStatusEnum.DELIVERED.value){
					this.stepActive = 3
				}
				if(this.order.receipt_status == ReceiptStatusEnum.RECEIVED.value){
					this.stepActive = 5
				}
				if(this.order.order_status == OrderStatusEnum.COMPLETED.value){
					this.stepActive = 5
				}
				
			}
		},
		methods: {
			getOrderDetail(){
				this.$emit('getOrderDetail')
			},
			// 跳转到订单评价页
			handleTargetComment() {
			  this.$router.push('/myhome/evaluate?id='+this.orderId)
			},
			handleInvoice(){
				this.dialogInvoice = true
			},
			onApplyInvoice(){
				let app = this
				let invoice = this.invoice
				this.$refs['invoiceform'].validate((valid) => {
				  if (valid) {
				    if((invoice.pushMode == 0 || invoice.pushMode == 2) && !invoice.email){
						this.$message.error('请输入接收发票的邮箱号')
						return
					}
					if((invoice.pushMode == 1 || invoice.pushMode == 2) && !invoice.buyerPhone){
						this.$message.error('请输入接收发票的手机号')
						return
					}
					OrderApi.invoice(app.orderId, invoice.buyerName, invoice.buyerTaxNum, invoice.buyerTel,
					invoice.buyerAddress, invoice.buyerAccount, invoice.pushMode, invoice.buyerPhone, invoice.email, invoice.invoiceLine)
					  .then(result => {
						app.dialogInvoice = false
						// 显示成功信息
						app.$message.success('电子发票申请成功')
						// 刷新订单列表
						app.getOrderDetail()
					  })
				  } else {
				    console.log('error submit!!');
				    return false;
				  }
				});
			},
			handleInvoicePdf(){
				let app = this
				OrderApi.invoiceview(app.order.invoice_num)
				  .then(result => {
					  console.log(result)
					  let pdfUrl = result.data.pdfUrl
					  let link = document.createElement('a')
					  link.style.display = 'none'
					  link.target = '_blank'
					  link.href = pdfUrl
					  link.setAttribute('download', app.order.order_no + '-电子发票')
					  document.body.appendChild(link)
					  link.click()
				  })
			},
			// 取消订单
			onCancel() {
			  const app = this
			  app.$confirm('确认要取消该订单吗?', '提示', {
			  	confirmButtonText: '确定',
			  	cancelButtonText: '取消',
			  	type: 'warning'
			  }).then(() => {
			  	OrderApi.cancel(app.orderId)
			  	  .then(result => {
			  	    // 显示成功信息
			  	  app.$message.success(result.message)
			  	    // 刷新订单列表
			  	    app.getOrderDetail()
			  	  })
			  	})
			},
			
			// 确认收货
			onReceipt() {
			  const app = this
			  app.$confirm('确认收到商品了吗?', '提示', {
			  	confirmButtonText: '确定',
			  	cancelButtonText: '取消',
			  	type: 'warning'
			  }).then(() => {
			  	OrderApi.receipt(app.orderId)
			  	  .then(result => {
			  	    // 显示成功信息
			  		app.$message.success(result.message)
			  	    // 刷新订单列表
			  	    app.getOrderDetail()
			  	  })
			  })
			},
			
			// 点击去支付
			onPay() {
			  // 显示支付方式弹窗
			  this.dialogVisible = true
			},
			
			// 选择支付方式
			onSelectPayType(payType) {
			  const app = this
			  // 隐藏支付方式弹窗
			  this.dialogVisible = false
        if (payType == PayTypeEnum.WECHAT.value) {
          app.$router.push('/settlement/wxpay?id='+app.orderId)
          return
        }
			  // 发起支付请求
			  OrderApi.pay(app.orderId, payType)
			    .then(result => app.onSubmitCallback(result))
			    .catch(err => err)
			},
			
			// 订单提交成功后回调
			onSubmitCallback(result) {
			  const app = this
			  // 余额支付
			  if (result.data.pay_type == PayTypeEnum.BALANCE.value) {
          app.$message.success('支付成功')
			    setTimeout(() => {
			      // 刷新当前订单数据
			      app.getOrderDetail()
			    }, 1500)
			  }
			  //诺诺支付
			  if (result.data.pay_type == PayTypeEnum.NUONUO.value) {
			  	let payment = JSON.parse(result.data.payment)
				if(payment.code != "JH200"){
					this.$message.error(payment.describe)
				}else{
					this.$router.push('/settlement/nuonuo?id='+result.data.order_id+'&payment='+encodeURIComponent(payment.result.payUtl))
				}
			  }
			},
			// 立即付款
			payClick() {
				this.$router.push('/settlement');
			},
			// 催单
			reminderClick() {
				this.$alert('催单已发出，请您耐心等待！', '催单');
			},
			// 取消订单
			cancelClick() {
				this.$confirm('确定取消订单吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$message({
						type: 'success',
						message: '取消成功!'
					});
				}).catch(() => {});
			},
			// 确认收货
			receivingClick() {
				this.$confirm('请确认是否已收到货？?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$message({
						type: 'success',
						message: '确认收货成功!'
					});
				}).catch(() => {});
			},
			// 评价
			evaluateClick() {
				this.$router.push('/myhome/evaluate');
			},
		}
	}
</script>

<style>
</style>
