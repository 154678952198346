<template>
	<div class="logistics">
		<el-timeline>
			<el-timeline-item>
				物流公司：{{order.express.express_name}}
			</el-timeline-item>
			<el-timeline-item>
				物流单号：{{order.express_no}}
			</el-timeline-item>
		</el-timeline>
	</div>
</template>

<script>
	export default {
		props:{
			order: Object
		},
		data() {
			return {
			};
		}
	};
</script>

<style>
</style>
