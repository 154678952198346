<template>
	<div class="order-details">
		<!-- 订单状态 -->
		<div class="state-wrapper">
			<div class="my-title clearfix">
				<span class="">订单详情</span>
			</div>
			<orderStatus :order="order" @getOrderDetail="getOrderDetail"></orderStatus>
		</div>
		
		<!-- 物流信息 -->
		<!-- <div class="logistics-wrapper" v-if="order.express_no != 'faw'">
			<div class="my-title clearfix">
				<span class="">物流信息</span>
			</div>
			<orderLogistics :order="order"></orderLogistics>
		</div> -->
		
		<!-- 订单信息 -->
		<div class="order-info-wrapper">
			<div class="my-title clearfix">
				<span class="">订单信息</span>
			</div>
			<orderInfo :order="order" :setting="setting" @refresh="getOrderDetail"></orderInfo>
		</div>
		
	</div>
</template>

<script>
	import {
	  DeliveryStatusEnum,
	  DeliveryTypeEnum,
	  OrderStatusEnum,
	  PayStatusEnum,
	  PayTypeEnum,
	  ReceiptStatusEnum
	} from '@/common/enum/order'
	import * as OrderApi from '@/api/order'
	import orderStatus from "./order-status.vue"
	import orderLogistics from "./order-logistics.vue"
	import orderInfo from "./order-info.vue"
	export default {
		components:{orderStatus,orderLogistics,orderInfo},
		data() {
			return {
				// 枚举类
				DeliveryStatusEnum,
				DeliveryTypeEnum,
				OrderStatusEnum,
				PayStatusEnum,
				PayTypeEnum,
				ReceiptStatusEnum,
				// 当前订单ID
				orderId: null,
				// 当前订单详情
				order: {},
				// 当前设置
				setting: {},
				dialogVisible: false,
			}
		},
		created() {
			if(this.$route.query.id){
				this.orderId = this.$route.query.id
			}
			// 获取当前订单信息
			this.getOrderDetail()
		},
		methods: {
			// 获取当前订单信息
			getOrderDetail() {
			  const app = this
			  OrderApi.detail(app.orderId)
			    .then(result => {
			      app.order = result.data.order
				  app.order.total_num = 0
				  app.order.goods.forEach(goods => {
				    app.order.total_num += goods.total_num
				  })
			      app.setting = result.data.setting
			    })
			},
			
		}
	}
</script>

<style lang="scss">
	@import "../../assets/css/order-details.scss";
</style>
