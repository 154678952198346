<template>
	<div class="order-info">
		<div class="import">
			<el-button size="mini" type="primary" @click="handleDownload()" class="btn-import">导出明细</el-button>
			<a target="_blank" :href="reportUrl+order.order_id+'&tds='+tdsNo" class="link-import">打印&导出</a>
		</div>
		<div class="order-info-con">
			<ul>
				<li><span>订单编号：</span>{{ order.order_no }}</li>
				<li><span>下单时间：</span>{{ order.create_time }}</li>
				<li v-if="order.pay_type == PayTypeEnum.WECHAT.value"><span>支付方式：</span>微信支付</li>
				<li v-if="order.pay_type == PayTypeEnum.NUONUO.value"><span>支付方式：</span>线上支付</li>
				<li v-if="order.pay_type == PayTypeEnum.NUOAPP.value"><span>支付方式：</span>线上支付</li>
				<li v-if="order.pay_type == PayTypeEnum.BALANCE.value"><span>支付方式：</span>余额支付</li>
				<li><span>收货人：</span>{{ order.address.name }}</li>
				<li><span>收货人手机号码：</span>{{ order.address.phone }}</li>
				<li><span>收货人地址：</span>
					<span  v-for="(region, idx) in order.address.region" :key="idx">{{region}}</span>
					{{ order.address.detail }}</li>
				<li><span>买家留言：</span>{{ order.buyer_remark ? order.buyer_remark : '--' }}</li>
			</ul>
		</div>
		<el-tabs @tab-click="handleTdsClick" v-if="deliveryList.length > 0">
		    <el-tab-pane label="全部" name=""></el-tab-pane>
		    <el-tab-pane v-for="(item, index) in deliveryList" :key="index" :label="item.warehouse_name+': ' + item.tds_no" :name="item.tds_no" class="info">
				<p><span>发货仓库：</span>{{item.warehouse_name}}</p>
				<p><span>物流公司：</span>{{item.express_name}}</p>
				<p><span>物流单号：</span>{{item.express_no}}</p>
                <p><span>物流电话：</span>{{item.express_phone}}</p>
				<p v-if="item.status == 2"><span>收货状态：</span>已收货</p>
<!--				<p><span>物流电话：</span>{{item.express_phone}}</p>-->
<!--				<p><span>收件人：</span>{{item.express_receiver}}</p>-->
<!--				<p><span>备注：</span>{{item.express_remark}}</p>-->
				<p v-if="order.delivery_status == DeliveryStatusEnum.DELIVERED.value && order.receipt_status == ReceiptStatusEnum.NOT_RECEIVED.value">
					<el-button v-if="item.status == 1" size="mini" @click="handleTdsReceive(order.order_id, item.tds_no)" style="margin-bottom: 30px;" plain>确认收货(本TDS订单)</el-button>
				</p>
			</el-tab-pane>
            <el-tab-pane label="已退款明细" name="refund"></el-tab-pane>
		  </el-tabs>
		<el-table :data="loadGoodsList()" class="settlement-table" ref="multipleTable" :cell-class-name="cellcb" @selection-change="handleSelectionChange" @select-all="handleSelectionAllChange">
			<el-table-column type="selection" width="55"></el-table-column>
			<el-table-column type="index" label="序号" width="50" align="center">
			</el-table-column>
			<el-table-column prop="goods.buy_code" label="订货备件代码" width="200"></el-table-column>
			<el-table-column prop="goods.sup_code" label="供应商代码" align="center" width="100"></el-table-column>
			<el-table-column prop="goods_name" label="零件名称">
				<template slot-scope="scope">
					<div class="goods-name">
						<i class="el-icon-picture" @click="imgViewer(scope.row.goods_image)"></i>
						<router-link :to="{ path: '/details', query: { id: scope.row.goods_id } }"  target='_blank'>
							{{scope.row.goods_name}}
						</router-link>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="grade_goods_price" label="单价（元）" align="center" width="90">
                <template slot-scope="scope">
                    <span class="goods-price">¥{{ scope.row.grade_goods_price > 0 ? scope.row.grade_goods_price : scope.row.goods_price }}</span>
                </template>
			</el-table-column>
			<el-table-column prop="total_num" label="数量" align="center" width="80">
			</el-table-column>
			<el-table-column prop="" label="配货数量" align="center" width="80" v-if="tdsNo !== 'refund'">
				<template slot-scope="scope">
					{{getDeliveryNum(scope.row)}}
				</template>
			</el-table-column>
			<el-table-column prop="" label="退货数量" align="center" width="80">
				<template slot-scope="scope">
					{{getRefundNum(scope.row)}}
				</template>
			</el-table-column>
			<el-table-column prop="spec" label="小计（元）" align="center" width="120">
				<template slot-scope="scope">
					<span class="goods-price" v-if="tdsNo !== 'refund'">¥{{ scope.row.total_price }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="remark" label="备注" align="center" width="120">
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<span v-if="scope.row.is_refunded" style="margin: 0 10px 0 0;">已申请售后</span>
					<el-button class="button-primary-mini" type="primary" size="mini"  v-else @click="handleApplyRefund(scope.row.order_goods_id)">申请售后</el-button>
					<!-- <el-button class="button-warning-mini" type="danger" size="mini" @click="evaluateClick">评价</el-button> -->
					<el-button class="button-warning-mini" type="danger" size="mini"  @click="remarkClick(scope.row.order_goods_id, scope.row.remark)">备注</el-button>
				</template>
			</el-table-column>
		</el-table>

		<div class="table-subtotal" v-if="tdsNo !== '' && tdsNo != 'refund'">
			<span>小计</span>
			<em>￥{{tab_total_price}}</em>
		</div>
		<el-button type="primary" @click="batchApply" style="margin-top: 10px;">批量申请售后</el-button>
		<!-- 结算 -->
		<div class="settlement-bottom clearfix">
			<p><span>¥{{ order.total_price }}</span><em><i>{{ order.total_num }}</i>件商品，总计：</em></p>
			<!-- <p><span>+￥{{ order.express_price }}</span><em>运费：</em></p> -->
			<p><span>-￥{{ order.coupon_money }}</span><em>优惠券抵扣：</em></p>
			<p v-if="order.points_money > 0"><span>-￥{{ order.points_money }}</span><em>{{ setting.points_name }}抵扣：</em></p>
			<p v-if="order.update_price.value != '0.00'">
				<span>￥{{ order.update_price.value }}</span><em>后台改价：</em></p>
			<p class="total"><span>¥{{ order.pay_price }}</span><em>应付金额：</em></p>
		</div>

		<el-dialog title="设置备注" :visible.sync="dialogVisible" width="30%">
			<el-form ref="form" :model="form" label-width="80px">
			  <el-form-item label="备注">
			    <el-input v-model="form.remark"></el-input>
			  </el-form-item>
			  <el-form-item>
			    <el-button type="primary" @click="handleRemark">保存</el-button>
			  </el-form-item>
			</el-form>
		</el-dialog>
		<!-- 图片预览 -->
		<el-image-viewer v-if="showPictureView" :zIndex="Number(99999)" :on-close="closeImgViewer" :url-list="viewImgList" :initialIndex="viewPicIndex" />
	</div>
</template>

<script>
	import elImageViewer from 'element-ui/packages/image/src/image-viewer';
	import {
	  DeliveryStatusEnum,
	  DeliveryTypeEnum,
	  OrderStatusEnum,
	  PayStatusEnum,
	  PayTypeEnum,
	  ReceiptStatusEnum
	} from '@/common/enum/order'
	import * as OrderApi from '@/api/order'
  import {RefundStatusEnum} from "@/common/enum/order/refund";

	export default {
		components: {elImageViewer},
		props:{
			order: Object,
			setting: Object
		},
        watch: {
            order: {
                handler: function (val) {
                    let deliveryList = []
                    let expressList = val.fawexpress
                    val.goods.forEach(item => {
                        item.is_refunded = this.getRefunded(item)
                        item.delivery.forEach(d => {
                            if(!deliveryList.find(i => i.tds_no == d.tds_no)){
                                deliveryList.push({
                                    tds_no: d.tds_no,
                                    warehouse_name: d.warehouse_name,
                                    warehouse_no: d.warehouse_no,
                                })
                            }
                        })
                    })
                    deliveryList.forEach(item => {
                        let express = expressList.find(d => d.tds_no == item.tds_no)
                        if(express) {
                            item.express_name = express.express_name
                            item.express_no = express.express_no
                            item.status = express.status
                        }
                    })
                    this.deliveryList = deliveryList
                },
                deep: true,
                immediate: true
            }
        },
		data() {
			return {
				tab_total_price:'',
				multipleSelection: [],
				showPictureView: false,
				viewPicIndex: 0,
				viewImgList: [],
				reportUrl: process.env.VUE_APP_REPORT_URL + '588863542348484608?order_id=',
				// 枚举类
				DeliveryStatusEnum,
				DeliveryTypeEnum,
				OrderStatusEnum,
				PayStatusEnum,
				PayTypeEnum,
				ReceiptStatusEnum,
				tdsNo: '',
				dialogVisible: false,
				form: {
					order_goods_id: '',
					remark: ''
				},
                deliveryList: []
			};
		},
		methods:{
			// 批量申请售后
			batchApply(){
				if(this.multipleSelection.length==0){
					this.$message({
						message: '请至少选择一个商品!',
						type: 'warning'
					});
				}else{
					let orderGoodsId=[]
					this.multipleSelection.forEach((item)=>{
						orderGoodsId.push(item.order_goods_id)
					})
					this.$router.push('/myhome/returngoods?orderGoodsId=' + orderGoodsId)
				}
			},
			// 全选
			handleSelectionAllChange(val){
				this.multipleSelection=val.filter((item)=>{
					return !item.is_refunded
				})
			},
			// 多选
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 已申请售后不显示多选
			cellcb({ row }){
			 	return row.is_refunded ? "myCell" : ''
			},
			// 图片预览
			imgViewer(img){
				this.showPictureView = true;
				this.viewImgList=[img]
			},
			closeImgViewer() {
				this.showPictureView = false;
			},
			// 跳转到申请售后页面
			handleApplyRefund(orderGoodsId) {
			  this.$router.push('/myhome/returngoods?orderGoodsId=' + orderGoodsId)
			},
			handleTdsClick(e){
				this.tdsNo = e.name
				console.log(this.tdsNo)
			},
			loadGoodsList() {
					let list = this.order.goods
					if(this.tdsNo && this.tdsNo != 'refund') {
							let total_price = 0
							list = JSON.parse(JSON.stringify(list.filter(i => i.delivery.find(d => d.tds_no == this.tdsNo))))
							list.forEach(item => {
									item.total_price = (this.getDeliveryNum(item) * item.grade_goods_price).toFixed(2)
									total_price += Number(item.total_price)
							})
							this.tab_total_price = total_price.toFixed(2)
							// todo, TDS tab页下显示小计
							// list.push({
							//     goods_name: '小计',
							//     total_price: total_price.toFixed(2),
							// })
					}
					if(this.tdsNo == 'refund') {
						let total_price = 0
							list = list.filter(i => i.is_refunded)
					}
					return list
			},
            getDeliveryNum(row) {
                let list = row.delivery
                if(this.tdsNo) {
                    list = list.filter(d => d.tds_no == this.tdsNo)
                }
                return list.reduce((acr, cur) => acr + cur.num, 0)
            },
            getRefundNum(row) {
                const refundList = this.order.refund.filter(r => r.status === RefundStatusEnum.NORMAL.value ||
                  r.status === RefundStatusEnum.COMPLETED.value)
                let list = refundList.reduce((sum, e) => sum.concat(e.refundOrderGoods), [])
                    .filter(g => g.order_goods_id === row.order_goods_id)

                if(this.tdsNo && this.tdsNo != 'refund') {
                    let delivery = this.deliveryList.find(d => d.tds_no == this.tdsNo)
                    console.log('delivery', delivery)
                    if(delivery) {
                        list = list.filter(d => d.warehouse_no == delivery.warehouse_no)
                    } else {
                        list = []
                    }
                }
                return list.reduce((acr, cur) => acr + cur.refund_num, 0)
            },
			handleDownload() {
                console.log(this.order.fawexpress)
			  let app = this

			  let filename = app.order.order_no

              let goods = []
              app.order.goods.forEach(item => {
                  if(item.delivery.length > 0) {
                      item.delivery.forEach(d => {
                          goods.push({
                              ...item,
                              delivery_num: d.num,
                              tds_no: d.tds_no,
                              total_price: (item.grade_goods_price * d.num).toFixed(2),
                              warehouse_name: d.warehouse_name,
                              refund_num: item.refund.filter(r => r.warehouse_no == d.warehouse_no).reduce((sum, e) => sum + e.refund_num, 0)
                          })
                      })
                  } else {
                        goods.push({
                            ...item,
                            delivery_num: 0,
                            tds_no: '',
                            warehouse_name: '',
                            refund_num: item.refund.reduce((sum, e) => sum + e.refund_num, 0)
                        })
                  }
              })

			  import('@/vendor/Export2Excel').then(excel => {
			    const tHeader = ['订货备件代码', '供应商代码', '备件名称', '单价', '数量', '配货数量', '退货数量', '小计', 'TDS单号', '发货仓库']
			    const filterVal = ['goods_no', 'sup_code', 'goods_name', 'grade_goods_price', 'total_num', 'delivery_num', 'refund_num',
				'total_price', 'tds_no', 'warehouse_name']
			    const data = this.formatJson(filterVal, goods)
			    excel.export_json_to_excel({
			      header: tHeader,
			      data,
			      filename: filename,
			      autoWidth: true,
			      bookType: 'xlsx'
			    })
			  })
			},
			formatJson(filterVal, jsonData) {
			  let _this = this
			  return jsonData.map(v => filterVal.map(j => {
				console.log()
			    if (j === 'goods_no' && v[j]) {
			      return v.goods['buy_code']
			    }
				if (j === 'sup_code') {
				  return v.goods[j]
				}
                if (j === 'grade_goods_price') {
                    if (v.grade_goods_price > 0) {
                        return v.grade_goods_price
                    } else {
                        return v['goods_price']
                    }
                }
			    return v[j]
			  }))
			},
			remarkClick(order_goods_id, remark){
				this.form.order_goods_id = order_goods_id
				this.form.remark = remark
				this.dialogVisible = true
			},
			handleRemark(){
				OrderApi.updateremark(this.form.order_goods_id, this.form.remark)
				  .then(result => {
					this.$message.success('保存成功')
					this.dialogVisible = false
				    this.$emit('refresh')
				  })
			},
			handleTdsReceive(orderId, tds){
				const app = this
				app.$confirm('确认收到商品了吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					OrderApi.tdsreceive(orderId, tds)
					  .then(result => {
						  console.log(result)
						app.$message.success('已确认收货')
						if(result.data.res == 0){
							app.orderReceive(orderId)
						}else{
							app.$emit('refresh')
						}
					  })
				})
			},
			orderReceive(orderId){
				let app = this
				OrderApi.receipt(orderId)
				  .then(result => {
				    // 刷新订单列表
				    app.$emit('refresh')
				  })
			},
            getRefunded (goods) {
                const total_refund_num = this.getRefundNum(goods)
                return total_refund_num >= goods.total_num
            },
		}
	}
</script>

<style lang="scss">
	@import "../../assets/css/settlement.scss";
</style>
<style lang="scss" scoped>
	::v-deep .myCell .el-checkbox__input {
	  display: none
	}
	.settlement-table{
		// margin: 0 0 20px 0;
		.goods-name {
			i {
				color: $main-color;
				cursor: pointer;
			}
		}

		.goods-price {
			color: $main-red-color;
			font-size: 16px;
		}
	}
	.table-subtotal{
		background: rgba(0, 0, 0, 0.04);
		display: flex;
		justify-content: space-between;
		padding: 6px 0;
		span{
			width: 50px;
			text-align: center;
		}
		em{
			margin-right: 320px;
			width: 120px;
			text-align: center;
			color: $main-red-color;
			font-size: 16px;
		}
	}

</style>
